<template>
  <div class="webgl-container">
    <div id="webglDom" ref="webglDom"></div>
  </div>
</template>

<script>
// 引入threejs核心模块
import * as THREE from "three";
// 引入OrbitControls
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// 引入FBXLoader
// import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
// 引入GLTFLoader
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
export default {
  name: "ThreePage",
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      textloader: null,
      width: 0,
      height: 0,
    };
  },
  watch: {
    skyType() {
      this.addSkeyBox();
    },
  },
  mounted() {
    this.$nextTick(this.init);
  },
  methods: {
    init() {
      // 初始化画布宽高
      const container = this.$refs.webglDom;
      this.width = container.offsetWidth;
      this.height = container.offsetHeight;

      // 场景
      this.scene = new THREE.Scene();
      // this.scene.fog = new THREE.Fog(0x005577, 1, 2800);
      this.textloader = new THREE.TextureLoader();

      // 相机
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.width / this.height,
        0.01,
        10000
      );
      this.camera.position.set(1.3, 1.3, 1.3);
      this.add(this.camera);
      this.camera.lookAt(this.scene.position);

      // // AxesHelper
      // let axisHelper = new THREE.AxesHelper(100, 100);
      // this.add(axisHelper);

      // 添加灯光
      this.addLight();
      this.addBox();

      // 渲染器
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
      });
      this.renderer.setClearColor(new THREE.Color("#000000"), 1);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      this.renderer.setSize(this.width, this.height);
      document.getElementById("webglDom").appendChild(this.renderer.domElement);

      // OrbitControls
      new OrbitControls(this.camera, this.renderer.domElement);
      this.render();
    },

    addBox() {
      // 加载模型
      var loader = new GLTFLoader();
      loader.load(
        "https://wmfiles.oss-cn-beijing.aliyuncs.com/3d-demo/20230223/closes.glb",
        (glb) => {
          glb.scene.position.set(0, 0, 0);
          this.add(glb.scene);
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );
    },

    addLight() {
      // 环境光
      const light = new THREE.AmbientLight(0xffffff, 0.5); // soft white light
      this.add(light);

      // // 平行光源
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(200, 600, 200);
      this.add(directionalLight);
    },
    add(obj) {
      this.scene.add(obj);
    },
    render() {
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.render);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#webglDom,
.webgl-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>